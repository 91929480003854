
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";

import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { ScrollComponent } from "@/presentation/assets/ts/components/_ScrollComponent";
import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
import Swal from "sweetalert2";
import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";
import { Timer } from "@/presentation/helper/Timer";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";
import { ApiService } from "@/infrastructure/api/ApiService";
import { ChatLogAttribute } from "@/domain/pollster/model/chatLog/ChatLogAttribute";
import { ChatSectionResponse } from "@/domain/pollster/model/chatSection/ChatSectionResponse";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
    MessageIn,
    MessageOut,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    let webSocket: WebSocket;
    const videoElement = ref<null | HTMLVideoElement>(null);
    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);
    const isGifPlaying = ref(false);
    const isRecording = ref(false);
    const isLoadingResponse = ref(false);
    const isAiActive = ref(false);
    const audioList: HTMLAudioElement[] = [];
    const AItext = ref("");
    const AIspeakSecond = ref(0);

    const codeText = ref("");

    const timer = new Timer();
    const jwtTokenManager = new JwtTokenManager();
    let completionPercentage = ref(0);
    const duration = ref("00:00");
    const sessionTime = localStorage.getItem(LOCAL_STORAGE.TIMER);

    onBeforeUnmount(() => {
      timer.stopTimer();
    });

    function getUserDeviceInfo() {
      const deviceId = Math.random().toString(36).substring(2, 10);
      const deviceModel = navigator.appVersion;
      const lang = navigator.language;
      const osVersion = navigator.platform;

      const formData = new FormData();
      formData.append("deviceId", deviceId);
      formData.append("deviceModel", deviceModel);
      formData.append("lang", lang);
      formData.append("osVersion", osVersion);

      const jsonData: { [key: string]: any } = {};

      formData.forEach((value: any, key: any) => {
        jsonData[key] = value;
      });

      return JSON.stringify(jsonData);
    }

    function AIMessageFlow(
      text: string | any,
      speakSecond: number,
      messagesRef: ChatLogAttribute[]
    ) {
      console.log(speakSecond);
      let count = 0;
      let artis = text.length / (speakSecond * 10);
      let interval = setInterval(() => {
        if (count >= text.length || !messagesRef.length)
          clearInterval(interval);

        const lastMessageIndex = messages.value.length - 1;

        if (lastMessageIndex >= 0 && lastMessageIndex < messagesRef.length) {
          messagesRef[lastMessageIndex].text = text.slice(0, count);
        }
        count += artis;
        ScrollComponent.updateAll();
      }, 100);
    }

    const messages = ref<Array<ChatLogAttribute>>([
      {
        owner: "AI",
        text: t("modals.pollster.firstAiMessage"),
        createdAt: new Date().toLocaleTimeString("tr-TR", {
          hour: "2-digit",
          minute: "2-digit",
        }),
      },
    ]);

    watch(AItext, (newValue) => {
      if (messages.value.length > 0) {
        messages.value[messages.value.length - 1].text = newValue;
      }
    });

    const startChat = () => {
      const data = {
        jwt: jwtTokenManager.getToken(),
        sessionId: codeText.value,
      };

      if (data.jwt == null || data.jwt == "null" || data.jwt == "undefined") {
        webSocket = new WebSocket(
          `wss://socket.pollster.ogzatech.com?sessionId=${data.sessionId}`
        );
      } else {
        webSocket = new WebSocket(
          `wss://socket.pollster.ogzatech.com?jwt=${data.jwt}&sessionId=${data.sessionId}`
        );
      }
      webSocket.addEventListener("open", () => {
        startUserCamera();
        isAiActive.value = true;
        isLoadingResponse.value = true;
        messages.value = [];

        webSocket.addEventListener("message", (response) => {
          let res: ChatSectionResponse = JSON.parse(response.data);
          console.log("res.data", res.data);
          if (res.data == null) {
            Swal.fire({
              text:
                codeText.value +
                " | Sistemde teknik bir hata mevcut. Lütfen daha sonra tekrar deneyiniz.",
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: "Tamam",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({ name: "chat-with-AI" });
            });
          } else {
            if (res.jwt) {
              const userDeviceData = getUserDeviceInfo();
              webSocket.send(userDeviceData);

              if (sessionTime !== null) {
                const [hours, minutes] = sessionTime.split(":");

                const hoursInNumber = parseInt(hours, 10);
                const minutesInNumber = parseInt(minutes, 10);

                const totalMinutes = hoursInNumber * 60 + minutesInNumber;

                timer.startTimer((formattedDuration: string) => {
                  duration.value = formattedDuration;
                }, totalMinutes);
              } else {
                timer.startTimer((formattedDuration: string) => {
                  duration.value = formattedDuration;
                });
              }

              jwtTokenManager.saveToken(res.jwt);

              if (jwtTokenManager.getToken()) {
                isAiActive.value = true;
                Swal.fire({
                  text: codeText.value + " - Görüşme başladı.",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Tamam",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(function () {
                  router.push({ name: "chat-with-AI" });
                });
              } else {
                Swal.fire({
                  text: codeText.value + " - Görüşme başlayamadı.",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Tamam",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(function () {
                  router.push({ name: "chat-with-AI" });
                });
              }
            }

            if (res.data.attributes.productSessionMessages.data.length > 0) {
              const newMessages = res.data.attributes.productSessionMessages.data.map(
                (message: any) => ({
                  owner: message.attributes.owner,
                  text: message.attributes.text,
                  createdAt: new Date(
                    message.attributes.createdAt
                  ).toLocaleTimeString("tr-TR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  }),
                })
              );

              messages.value = newMessages;
              ScrollComponent.updateAll();
              const lastMessage = newMessages[messages.value.length - 1];

              if (res.data.attributes.voice.data) {
                let url =
                  "https://api.pollster.ogzatech.com" +
                  res.data.attributes.voice.data.attributes.url;

                startAIVoice(url).then(() => {
                  if (lastMessage.owner == "AI") {
                    AIMessageFlow(
                      lastMessage.text,
                      Number(AIspeakSecond.value),
                      messages.value
                    );
                  }
                });
              }

              completionPercentage.value =
                res.data.attributes.completionPercentage;
            }

            if (res.data.attributes.endDate) {
              Swal.fire({
                title: "Tebrikler!",
                text:
                  "Görüşmeyi başarıyla tamamladınız. Bitirme süreniz:" +
                  duration.value,
                icon: "success",
              }).then(() => {
                timer.stopTimer();
                localStorage.clear();
                window.location.reload();
              });
            }
          }
        });
      });
    };

    let soundRecorder: MediaRecorder;
    let videoRecorder: MediaRecorder;
    let chunks: Blob[] = [];
    let videoChunks: Blob[] = [];

    let video: any;

    const startRecording = async () => {
      video = document.getElementById("userCamera");
      if (video.srcObject) {
        videoRecorder = new MediaRecorder(video.srcObject);
        videoRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            videoChunks.push(e.data);
            console.log(videoChunks);
          }
        };
        videoRecorder.start();
      }
      isRecording.value = true;
      stopAIVoice();
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          soundRecorder = new MediaRecorder(stream);
          soundRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              if (e.data.type.includes("audio")) {
                chunks.push(e.data);
              }
            }
          };
          soundRecorder.start();
        })
        .catch((err) => {
          console.error("Kayıt başlatılırken hata oluştu:", err);
        });
    };

    const startUserCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoElement.value) {
          videoElement.value.srcObject = stream;
          videoElement.value.style.display = "block";
        }
      } catch (error) {
        Swal.fire({
          text: "Kamera erişimi reddedildi veya kullanılamıyor.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          router.push({ name: "chat-with-AI" });
        });
      }
    };

    const stopRecording = () => {
      isRecording.value = false;
      if (
        soundRecorder &&
        soundRecorder.state !== "inactive" &&
        videoRecorder &&
        videoRecorder.state !== "inactive"
      ) {
        videoRecorder.stop();
        soundRecorder.stop();
        soundRecorder.onstop = () => {
          console.log(videoChunks);
          const audioBlob = new Blob(chunks, { type: "audio/mp3" });
          const videoBlob = new Blob(videoChunks, { type: "video/mp4" });
          sendBlobToAPI(audioBlob, videoBlob);
          chunks = [];
          videoChunks = [];
        };
      }
    };

    const sendBlobToAPI = (audioBlob: Blob, videoBlob: Blob) => {
      const apiService = new ApiService();

      const audioUrl = URL.createObjectURL(audioBlob);
      const audioElement = new Audio(audioUrl);

      audioElement.addEventListener("loadedmetadata", () => {
        if (
          audioElement.duration === Infinity ||
          isNaN(Number(audioElement.duration))
        ) {
          audioElement.currentTime = 1e101;
          audioElement.addEventListener("timeupdate", getDuration);
        }
      });

      function getDuration(event) {
        event.target.currentTime = 0;
        event.target.removeEventListener("timeupdate", getDuration);
        console.log(event.target.duration); // 4.92
        if (event.target.duration < 0.7) {
          isLoadingResponse.value = false;
          return Swal.fire({
            text: "Lütfen daha uzun ses kaydediniz.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            router.push({ name: "chat-with-AI" });
          });
        } else {
          const soundFile = new File([audioBlob], "userAudio.mp3", {
            type: "audio/mp3",
          });
          const formData = new FormData();
          formData.append("files", soundFile);

          isLoadingResponse.value = true;
          apiService
            .post<any>("/upload", formData)
            .then((response) => {
              let res = response.data;
              let soundId = res[0].id;

              const data = {
                voice: soundId,
              };

              webSocket.send(JSON.stringify(data));
              console.log("videoBlob", videoBlob);
              sendVideoToAPI(soundId, videoBlob);
            })
            .catch((error) => {
              console.log("error", error);
              return Swal.fire({
                text: "Lütfen tekrar deneyiniz.",
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "Tamam",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                router.push({ name: "chat-with-AI" });
              });
            });
        }
      }
    };

    const sendVideoToAPI = (soundId: string, videoBlob: Blob) => {
      const apiService = new ApiService();
      const videoFile = new File([videoBlob], "userVideo.mp4", {
        type: "video/mp4",
      });
      const formData = new FormData();
      formData.append("files", videoFile);

      apiService
        .post<any>("/upload", formData)
        .then((response) => {
          let res = response.data;
          let videoId = res[0].id;

          const data = {
            voice: soundId,
            video: videoId,
          };

          webSocket.send(JSON.stringify(data));
        })
        .catch((error) => {
          console.log("error", error);
          return Swal.fire({
            text: "Video gönderilemedi, lütfen tekrar deneyiniz.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            router.push({ name: "chat-with-AI" });
          });
        });
    };

    const stopChat = async () => {
      Swal.fire({
        title: "Emin misiniz?",
        text: codeText.value + " Kodlu görüşmeyi sonlandırmak ister misiniz?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Tamam",
        cancelButtonText: "İptal",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Görüşme sonlandı.",
            text: "Görüşmeyi başarıyla sonlandırdınız.",
            icon: "success",
          }).then(() => {
            const data = {
              closeSession: true,
            };

            webSocket.send(JSON.stringify(data));
            isAiActive.value = false;
            timer.stopTimer();
            localStorage.clear();
            window.location.reload();
          });
        }

        router.push({ name: "chat-with-AI" });
      });
    };

    const startAIVoice = (url: string) => {
      return new Promise((resolve, reject) => {
        isLoadingResponse.value = false;
        isGifPlaying.value = true;
        const aiVoice = new Audio(url);

        aiVoice.addEventListener("loadedmetadata", () => {
          AIspeakSecond.value = aiVoice.duration;
          resolve(aiVoice.duration);
        });

        aiVoice.play();
        audioList.push(aiVoice);

        aiVoice.onended = () => {
          isGifPlaying.value = false;
        };

        aiVoice.onerror = (error) => {
          reject(error);
        };
      });
    };

    const stopAIVoice = () => {
      audioList.forEach((audio) => {
        if (!audio.paused) {
          audio.pause();
          isGifPlaying.value = false;
          audio.currentTime = 0;
        }
      });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      ScrollComponent.bootstrap();

      if (!messagesRef.value) {
        return;
      }
    });

    return {
      isAiActive,
      isLoadingResponse,
      startChat,
      stopChat,
      startRecording,
      stopRecording,
      messagesInRef,
      messagesOutRef,
      videoElement,
      isGifPlaying,
      isRecording,
      AItext,
      completionPercentage,
      duration,
      messages,
      messagesRef,
      codeText,
    };
  },
});
